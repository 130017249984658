.mat-table {
  font-family: 'Titillium Web', sans-serif !important;
}

.mat-sort-header-button {
  padding-left: 0 !important;
}

.table-outer {
  /*min-height: 90vh;*/
  max-height: 90vh;
  padding-bottom: 40px;
  overflow: hidden;
}

.table-container mat-table {
  min-width: 100%;
  width: fit-content !important;
}

.table-container {
  max-height: calc(80vh - 56px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.mat-header-cell {
  padding-right: 20px;
  min-width: 60px;
  font-weight: bold !important;
}

mat-cell {
  user-select: text !important;
}

.mat-cell {
  padding-right: 20px;
  min-width: 60px;
  white-space: nowrap !important;
}
.mat-cell p {
  white-space: nowrap !important;
  padding-top: 0.6em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/*---------------------------- Column types -------------------------------*/

.po-code-column {
  min-width: 92px;
  max-width: 92px;
}

.invoice-number-column {
  min-width: 148px;
  max-width: 148px;
}
.invoice-number-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 140px;
  max-width: 140px;
}

.vendor-column {
  min-width: 90px;
  max-width: 90px;
}

.date-column {
  min-width: 100px;
  max-width: 100px;
}
.date-column p {
  text-align: center;
}

.date-time-column {
  min-width: 150px;
  max-width: 150px;
}
.date-time-column p {
  text-align: center;
}

.destination-column {
  max-width: 200px;
  min-width: 200px;
}
.destination-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 170px;
  max-width: 170px;
}

.quantity-column {
  min-width: 110px;
  max-width: 110px;
  padding-left: 0;
}
.quantity-column-title {
  min-width: 110px;
  max-width: 110px;
  display: flex;
  justify-content: flex-end;
  text-align: end;
}
.quantity-column p {
  text-align: right;
  min-width: 80px;
  padding-right: 5px;
}

.value-column {
  min-width: 130px;
  max-width: 130px;
  padding-left: 0;
}
.value-column-title {
  min-width: 130px;
  max-width: 130px;
  display: flex;
  justify-content: flex-end;
}
.value-column p {
  text-align: right;
  min-width: 100px;
  padding-right: 5px;
}

.value-column-small {
  min-width: 100px;
  max-width: 100px;
  padding-left: 0;
}
.value-column-title-small {
  min-width: 100px;
  max-width: 100px;
  padding-left: 30px;
}
.value-column-small p {
  text-align: right;
  min-width: 85px;
  padding-right: 5px;
}

.category-column {
  max-width: 130px;
  min-width: 130px;
}
.category-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 120px;
  max-width: 120px;
}

.ean-column {
  min-width: 140px;
  max-width: 140px;
}

.tax-column {
  min-width: 100px;
  max-width: 100px;
}

.sku-column {
  min-width: 150px;
  max-width: 150px;
}
.sku-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 145px;
  max-width: 145px;
}

.extra-flag-column {
  min-width: 50px;
  max-width: 50px;
}

.boolean-column {
  min-width: 80px;
  max-width: 80px;
  justify-content: center;
}
.boolean-column p {
  text-align: center;
  width: 100%;
}
.bigger-boolean-column {
  min-width: 100px;
  max-width: 100px;
}
.bigger-boolean-column p {
  text-align: center;
  width: 100%;
}

.lot-name-column {
  min-width: 120px;
  max-width: 120px;
  padding-left: 10px;
}
.lot-name-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 100px;
  max-width: 100px;
}

.title-column {
  max-width: 200px;
  min-width: 200px;
}
.title-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 170px;
  max-width: 170px;
}

.supplier-column {
  max-width: 120px;
  min-width: 120px;
}
.supplier-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 95px;
  max-width: 95px;
}

.origin-column {
  max-width: 150px;
  min-width: 150px;
}
.origin-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 115px;
  max-width: 115px;
}

.shipment-code-column {
  min-width: 270px;
  max-width: 270px;
}
.shipment-code-column p,
.shipment-code-column a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 260px;
  max-width: 260px;
}

.asn-column {
  min-width: 240px;
  max-width: 240px;
}
.asn-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 230px;
  max-width: 230px;
}

.shipment-status-column {
  padding-left: 30px;
  min-width: 150px;
  max-width: 150px;
}

.lot-select-column {
  min-width: 160px;
  max-width: 160px;
  padding-left: 10px;
}

.asin-column {
  min-width: 150px;
  max-width: 150px;
}
.asin-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 115px;
  max-width: 115px;
}

.carton-column {
  min-width: 180px;
  max-width: 180px;
}

.long-vendor-column {
  min-width: 400px;
  max-width: 400px;
}
.long-vendor-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 380px;
  max-width: 380px;
}

.art-code-column {
  min-width: 120px;
  max-width: 120px;
}
.art-code-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 100px;
  max-width: 100px;
}

.code-column {
  min-width: 120px;
  max-width: 120px;
}
.code-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 100px;
  max-width: 100px;
}

.destination-code-column {
  min-width: 85px;
  max-width: 85px;
}

.color-code-column {
  min-width: 100px;
  max-width: 100px;
}

.purchase-price-select-column {
  min-width: 250px;
  max-width: 250px;
  padding-left: 20px;
}

.long-title-column {
  min-width: 400px;
  max-width: 400px;
}
.long-title-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 380px;
  max-width: 380px;
}

.supplier-name-column {
  min-width: 400px;
  max-width: 400px;
}
.supplier-name-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 380px;
  max-width: 380px;
}

.business-name-column {
  min-width: 250px;
  max-width: 250px;
}
.business-name-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 230px;
  max-width: 230px;
}

.code-column {
  min-width: 100px;
  max-width: 100px;
}

.actions-edit-column {
  min-width: 100px;
  max-width: 100px;
}

.actions-show-column {
  min-width: 100px;
  max-width: 100px;
  padding-left: 10px;
}

.stock-location-column {
  min-width: 120px;
  max-width: 120px;
}
.stock-location-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 95px;
  max-width: 95px;
}

.stock-location-name-column {
  min-width: 250px;
  max-width: 250px;
}
.stock-location-name-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 380px;
  max-width: 380px;
}

.stock-location-address-column {
  min-width: 280px;
  max-width: 280px;
}
.stock-location-address-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 260px;
  max-width: 260px;
}

.ddt-column {
  min-width: 140px;
}
.ddt-column p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 130px;
  max-width: 130px;
}

.checker-col {
  max-width: 40px;
}

.details-column p {
  min-width: 100px;
  text-align: center;
}
.details-column {
  min-width: 120px;
  max-width: 120px;
}

.week-column {
  min-width: 30px;
  max-width: 30px;
  padding-right: 0;
  margin-right: 15px;
}
.week-column p {
  min-width: 30px;
  text-align: center;
}

.tracking-column {
  min-width: 150px;
  max-width: 150px;
}
.tracking-column p {
  min-width: 140px;
  max-width: 140px;
}

.order-id-column {
  min-width: 80px;
  max-width: 80px;
}

.shipment-order-id-column {
  min-width: 100px;
  max-width: 100px;
}

.shipment-status-check-column {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
  justify-content: center;
}
