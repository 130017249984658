/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'tables-styles.css';

$primary-color: #355d90;
$secondary-color: #ff9900;

body {
  font-family: 'Titillium Web', sans-serif !important;
  position: relative;
  min-height: 100%;
  height: 100vh;
}

.font-Titillium,
.mat-slide-toggle-content,
.mat-input-element {
  font-family: 'Titillium Web', sans-serif !important;
}

.email-custom {
  border-left: 1px solid $primary-color;
  padding-left: 10px;
  border-right: 1px solid $primary-color;
  padding-right: 10px;
  font-family: 'Titillium Web', sans-serif;
  color: $primary-color;
  font-size: 13px;
  margin-right: 15px;
}

/* ACTIVE SELECT */
.mat-focused .mat-form-field-label {
  color: $primary-color;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.center {
  text-align: center;
}

.mat-form-field-underline {
  background-color: $secondary-color;
}

.mat-paginator-container {
  font-family: 'Titillium Web', sans-serif;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary-color;
}

.mat-form-field-ripple {
  background-color: $secondary-color;
}

/*FLOAT*/
.float-right {
  float: right;
}

.float-left {
  float: left;
}

/*FONT-CUSTOM*/

.font-row {
  font-size: 13px;
}

.font-bold {
  font-weight: bold;
}

.title-custom {
  font-style: italic;
  color: $primary-color;
  font-family: 'Titillium Web', sans-serif !important;
}

/*CHECKBOX*/
.mat-checkbox-frame {
  border-color: $secondary-color;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $secondary-color;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0 !important;
}

/*MARGIN and PADDING SIZE*/

.margin-lr-0 {
  margin-left: 0;
  margin-right: 0;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-6 {
  margin-left: 6px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-left-20 {
  margin-left: 20px;
}

.margin-right-3 {
  margin-right: 3px;
}
.margin-right-6 {
  margin-right: 6px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-38 {
  margin-right: 38px;
}

.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-user {
  margin-top: 20px;
  margin-bottom: 60px;
}

.padding-left-0 {
  padding-left: 0;
}
.padding-left-15 {
  padding-left: 15px;
}
.padding-left-40 {
  padding-left: 40px;
}

.padding-left-100 {
  padding-left: 100px;
}
.padding-bottom-5 {
  padding-bottom: 5px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-15 {
  padding-bottom: 15px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-top-4 {
  padding-top: 4px;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-11 {
  padding-top: 11px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-top-18 {
  padding-top: 18px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-60 {
  padding-top: 60px;
}

.h-padding {
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.h-padding-box {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 10px;
}

.padding-right-0 {
  padding-right: 0px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}

/*MIN HEIGHT */
.min-height-box {
  min-height: 150px !important;
}

/*BORDER COLORS*/
.border-bottom-orange {
  margin-bottom: 20px;
  border-bottom: 2px solid $secondary-color;
  padding-bottom: 20px;
}

/*TEXT COLORS */
.text-blue {
  color: $primary-color;
}
.text-white {
  color: #fff;
}

/*BACKGROUND COLORS */
.background-blue {
  background-color: #14437e;
}

.background-light-blue {
  background-color: $primary-color;
}

/*INPUT CUSTOM */

.no-focus:focus {
  outline: none !important;
}

.sea-custom {
  border-radius: 20px !important;
  font-size: 12px !important;
  padding-left: 23px !important;
  padding-right: 23px !important;
  width: 200px;
  text-align: center;
  border: 2px solid $secondary-color;
  min-width: 250px;
}

input::-webkit-input-placeholder {
  font-size: 10px;
  color: $secondary-color;
}

/*BUTTON CUSTOM */
.btn-custom {
  border-radius: 20px !important;
  border: 0px;
  background-color: $primary-color !important;
  color: #fcfcfc !important;
  font-size: 12px !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
  font-family: 'Titillium Web', sans-serif;
  min-width: 160px;
  height: 34px;
}

.btn-fixed {
  width: 70px !important;
  height: 22px !important;
}

button:disabled,
button[disabled]:hover {
  cursor: not-allowed;
  background-color: #c3c3c399 !important;
  color: #f9f9f9 !important;
}

.btn-custom:active,
.btn-custom:hover {
  background-color: $secondary-color !important;
  outline: none !important;
}

.btn-custom:focus {
  outline: none !important;
}

.btn-delete {
  border-radius: 20px !important;
  border: 0px;
  background-color: red;
  color: #fcfcfc !important;
  font-size: 14px !important;
  font-family: 'Titillium Web', sans-serif;
  width: 150px;
  height: 34px;
}

.btn-product {
  border-radius: 20px !important;
  border: 0px;
  background-color: $primary-color !important;
  color: #fcfcfc !important;
  font-size: 12px !important;
  font-family: 'Titillium Web', sans-serif;
  width: 90px;
  height: 34px;
}
.btn-product:active,
.btn-product:hover {
  background-color: $secondary-color !important;
  outline: none !important;
}
.btn-product:focus {
  outline: none !important;
}

.btn-product2 {
  border-radius: 20px !important;
  border: 0px;
  background-color: $primary-color !important;
  color: #fcfcfc !important;
  font-size: 12px !important;
  font-family: 'Titillium Web', sans-serif;
  min-width: 24px !important;
  width: 70px;
  height: 24px;
  line-height: 0 !important;
}

/*TOOLBAR CUSTOM */

.bar-custom {
  height: 50px !important;
  padding-left: 0 !important;
}

/*FILTER CUSTOM */

.filt-custom {
  background-color: $secondary-color !important;
  max-height: 35px;
}

.filters-row {
  font-size: 12px !important;
  padding-top: 15px;
}

.filters-row mat-form-field {
  min-width: 220px;
  max-width: 220px;
}

.mat-paginator-icon {
  border-radius: 20px !important;
  background-color: $secondary-color !important;
  color: white;
}

/* FILTERS */
.panel {
  margin-bottom: 0 !important;
  background-color: transparent;
}

.black-panel {
  height: 100vh;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 200;
  top: 0;
}

.panel-no-submenu {
  margin-bottom: 0 !important;
}

.filter-position {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 998;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.filter-title {
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px !important;
}

mat-expansion-panel span.mat-expansion-indicator {
  transform: rotate(180deg) !important;
  /* -webkit-transform: rotate(180deg) !important; */
  border: 2px solid #fff;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 5px;
  border-radius: 50px;
}

mat-expansion-panel.mat-expanded span.mat-expansion-indicator {
  transform: rotate(0deg) !important;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: #fff;
  /* transform: rotate(-180deg) !important; */
  /* -webkit-transform: rotate(-180deg) !important; */
}

button.mat-paginator-navigation-previous.mat-icon-button,
button.mat-paginator-navigation-next.mat-icon-button {
  background-color: transparent !important;
}
.mat-select-arrow:active,
.mat-select-arrow:hover,
.mat-select-arrow:visited {
  color: rgba(0, 0, 0, 0.54) !important;
}

/* PO CUSTOM */
.po-element-custom {
  float: right !important;
  margin-bottom: 20px;
}

/* custom table */
.mat-row.ng-star-inserted:nth-child(2n) {
  background-color: #efefef;
  border-bottom: 1px solid transparent;
}

.mat-row.ng-star-inserted:nth-child(2n):hover,
.mat-row.ng-star-inserted:hover {
  background-color: #b6c5d5;
  border-bottom: 1px solid transparent;
}

.mat-row.ng-star-inserted {
  background-color: white;
}

.mat-slide-toggle-content {
  font-family: 'Titillium Web', sans-serif !important;
}

/* custom  border title */
.divisor-title {
  border: 1px solid #f90;
  width: 92%;
  float: left;
  margin-top: 40px;
  background-color: #f90;
}

.square {
  width: 14px;
  height: 14px;
  background-color: #f90;
  position: absolute;
  right: 8%;
  top: 33px;
}

.sticky {
  position: sticky;
  background-color: #fff;
  top: 75px;
}

.key-table-fixed {
  font-size: 13px;
  line-height: 11px;
}

.cdk-overlay-pane {
  width: unset !important;
}

mat-option {
  font-size: 12px !important;
}
.mat-option-text {
  font-family: 'Titillium Web', sans-serif !important;
}

.mat-menu-item {
  font-family: 'Titillium Web', sans-serif !important;
  font-size: 14px !important;
  max-height: 40px;
  line-height: 40px !important;
}

.mat-chip:not(.mat-basic-chip) {
  background-color: #f3c238;
  color: #fff;
  padding: 3px 5px !important;
  font-size: 11px;
}

.details-box {
  max-height: 80px !important;
  padding-top: 10px;
}

.container {
  padding-left: 0 !important;
  margin-right: 6% !important;
  margin-left: 6% !important;
  width: 88% !important;
}

.big-title {
  font-size: 24px !important;
  margin-top: 5px !important;
  color: $primary-color;
}

mat-checkbox#takesBackorder label,
mat-checkbox#takesFirstorder label,
mat-checkbox#takesSoftFirstorder label,
mat-checkbox#sendSSCC label,
mat-checkbox#sendSoftFirstorder label {
  font-weight: 100 !important;
}

.edit-border-bottom {
  border: 0 !important;
  border-bottom: 1px solid #e5e5e5 !important;
  margin-bottom: 20px !important;
}

.lot-title {
  font-size: 24px !important;
  color: #14437e;
}

.lot-kpi {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.height-box {
  height: 130px;
}

#shipment-accordion span.mat-expansion-indicator,
#keepa-exp span.mat-expansion-indicator {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}

#shipment-accordion .mat-expansion-panel-body,
#keepa-exp .mat-expansion-panel-body {
  background-color: whitesmoke;
}

#shipment-accordion.mat-expanded span.mat-expansion-indicator {
  transform: rotate(180deg) !important;
}

mat-expansion-panel#ship-actions-accordion {
  box-shadow: none;
}

mat-expansion-panel#ship-actions-accordion .mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.edit-shipped-form-field div.mat-form-field-infix {
  margin-top: -4px !important;
}

.actions-button {
  border-radius: 30px !important;
  border: 0;
  background-color: $primary-color !important;
  color: #fcfcfc !important;
  font-size: 12px !important;
  font-family: 'Titillium Web', sans-serif;
  /*align-content: center;*/
  width: 22px !important;
}
.actions-button:hover {
  background-color: $secondary-color !important;
}

.keepa-properties-panel {
  font-family: 'Titillium Web', sans-serif;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

mat-paginator#little-paginator {
  max-height: 40px !important;
}

mat-paginator#little-paginator .mat-paginator-container {
  max-height: 40px !important;
  min-height: 40px !important;
  height: 40px !important;
}

.fixed-height-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.small-table-title {
  padding-left: 5px !important;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
.small-table-title h4 {
  margin-top: 15px !important;
}

.display-none {
  display: none !important;
}

.border-bottom-title {
  border-bottom: 1px solid #efefef;
}

mat-cell a {
  cursor: pointer;
}

.width-100per {
  width: 100% !important;
}

#spoItemsMatGroup mat-tab-header {
  display: none !important;
}

.expand-icon {
  font-size: 22px;
}
.expand-icon:hover {
  color: $secondary-color;
  cursor: pointer;
}

.lot-edit-bulk-actions {
  padding-left: 0 !important;
  height: 50px !important;
  background-color: #f5f5f5;
}

.small-mat-form-field {
  max-width: 105px !important;
  min-width: 95px !important;
}

.key-table-fixed a {
  color: #fff;
  text-decoration: underline;
}
.key-table-fixed a:hover {
  color: $primary-color;
}

.refresh-btn {
  border-radius: 20px !important;
  border: 0px;
  background-color: $primary-color !important;
  color: #fcfcfc !important;
  font-size: 12px !important;
  font-family: 'Titillium Web', sans-serif;
  width: 34px;
  height: 34px;
}
.refresh-btn:hover {
  background-color: $secondary-color !important;
}

.logo-link {
  cursor: pointer;
  width: 150px;
  display: block;
}

.transit-title {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  height: 40px;
  max-height: 40px;
}

.btn-transit-time {
  border-radius: 20px !important;
  border: 0;
  background-color: $primary-color !important;
  color: #fcfcfc !important;
  font-size: 12px !important;
  font-family: 'Titillium Web', sans-serif;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  line-height: 0;
}
.btn-transit-time:active,
.btn-transit-time:hover {
  background-color: $secondary-color !important;
  outline: none !important;
}
.btn-transit-time:focus {
  outline: none !important;
}
button:disabled {
  background-color: #c3c3c399 !important;
}

.checkbox-small-text {
  padding-top: 15px;
  color: #333;
}
.checkbox-small-text .mat-checkbox-label {
  font-weight: normal;
}

mat-tab-label.mat-tab-disabled {
  display: none !important;
}

.dialog-container {
  width: 600px !important;
  height: fit-content !important;
  padding-bottom: 15px;
  padding-top: 15px;
}

.mat-dialog-content,
.mat-dialog-actions .confirm-text {
  font: 500 18px/20px Roboto, 'Helvetica Neue', sans-serif !important;
}

.mat-dialog-actions {
  text-align: center;
  padding: 48px 24px 0 !important;
  justify-content: center;
  min-height: unset !important;
  margin-bottom: unset !important;
}

.mat-dialog-title {
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
  background-color: $primary-color;
  color: #fcfcfc;
  margin: -24px -24px 48px !important;
  padding: 24px;
}

.smaller-icon-button {
  padding: unset !important;
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 0 !important;
}

.status-check-color-error {
  color: red;
}

.status-check-color-warning {
  color: orange;
}

.status-check-color-ok {
  color: green;
}

.in-progress-mat-menu {
  overflow: hidden !important;
  width: 280px;
  border-radius: 0 !important;
}

#subsectionMenu {
  height: 42px;
  font-size: 14px;
  background-color: #355d90;
  border-bottom: 2px solid #ff9900;
}
#subsectionMenu button {
  font-size: 14px;
  height: 42px;
  font-family: 'Titillium Web', sans-serif;
  color: #fff;
  background-color: transparent;
  border: 0;
}
